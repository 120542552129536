body {
    margin: 0;
    font-family: "Roboto";
    background-color: black;
    color: white;
    height: 100vh;
    width: 100vw;
}

#restart {
    background-color: black;
    border: solid 1px white;
    padding: 10px 20px;
    color: white;
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
    transition: all 0.5s;
    outline: initial;
}

#restart:hover {
    background-color: white;
    color: black;
}
